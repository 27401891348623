import { PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';

import {
  hideListing,
  unhideListing,
} from 'store/ducks/orgDashboard/listings.actions';
import { pushFlashMessage } from 'zustand-stores/flashMessagesStore';

type OwnProps = {
  listingId: string;
  listingType: string;
  orgId: string;
  render: (arg0: {
    hideListing: () => Promise<void>;
    unhideListing: () => Promise<void>;
    submitting: boolean;
  }) => ReactNode;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onHide?: (...args: Array<any>) => any;
};
type Props = OwnProps & {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hideListing: (...args: Array<any>) => any;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unhideListing: (...args: Array<any>) => any;
};
type State = {
  submitting: boolean;
};

class HideListingContainer extends PureComponent<Props, State> {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state = {
    submitting: false,
  };

  toggleListingHidden = (hide: boolean) => {
    this.setState({
      submitting: true,
    });
    const { orgId, listingId, listingType } = this.props;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/destructuring-assignment
    const toggle = hide ? this.props.hideListing : this.props.unhideListing;

    return toggle({
      orgId,
      listingId,
      listingType,
    }).then(() => {
      this.setState({
        submitting: false,
      });
      pushFlashMessage({ type: hide ? 'LISTING_HIDDEN' : 'LISTING_UNHIDDEN' });

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/destructuring-assignment
      if (this.props.onHide) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/destructuring-assignment
        this.props.onHide(hide ? 'LISTING_HIDDEN' : 'LISTING_UNHIDDEN');
      }
    });
  };

  hideListing = () => this.toggleListingHidden(true);

  unhideListing = () => this.toggleListingHidden(false);

  render() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line prefer-destructuring, react/destructuring-assignment
    const submitting = this.state.submitting;

    return (
      <>
        {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line react/destructuring-assignment
          this.props.render({
            hideListing: this.hideListing,
            unhideListing: this.unhideListing,
            submitting,
          })
        }
      </>
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default connect(null, {
  hideListing,
  unhideListing,
})(HideListingContainer);
